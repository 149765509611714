import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import 'dayjs/locale/en-in';
import { FormatFileSize, IsNullOrEmptyOrUndefined, EncryptText } from '../Utilities/String';
import { CustomAlert } from '../Utilities/CustomAlert'
import { Grid, Tooltip, Card, CardHeader, Avatar, Box, IconButton, CircularProgress, Alert, Button, Typography } from '@mui/material';
import { Close, FileDownloadOutlined, Refresh, Report, TimerOutlined, WatchLater } from '@mui/icons-material';
import { BASE_URL } from '../Utilities/APIURLs';
import { GetJSON, GetBLOB } from '../Utilities/APICall';
import { useSelector, useDispatch } from 'react-redux'
import { TimeDiffInHHMMSS } from '../Utilities/Date';
import Timer from '../Components/Timer';

export function UserReport(props) {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const isMobile = screenWidth <= 768;

    const userinfo = useSelector((state) => state.userinfo);
    const downloadreport = useSelector((state) => state.downloadreport);
    const dispatch = useDispatch();
    const dateformat = 'DD MMM YY HH:mm:ss';
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const [downloading, setDownloading] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState(0);
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (userinfo.userid > 0 && dataFetchedRef.current === false) {
            getUserReport();
            dataFetchedRef.current = true;
        }
    }, [userinfo.userid]);

    useEffect(() => {
        if (IsNullOrEmptyOrUndefined(downloadreport.date) === false) {
            getUserReport();
            downloadreport.date = ''
        }
    }, [downloadreport.date]);

    /** API Calls */
    const getUserReport = () => {
        setLoading(true)

        var url = BASE_URL + 'UserReport/Get'
        GetJSON(url, props.instance)
            .then(data => {
                setLoading(false)
                if (IsNullOrEmptyOrUndefined(data.message) === false)
                    CustomAlert(data.message, 'error')
                else
                    if (data.data.length > 0) {
                        data.data.forEach(item => {
                            if (IsNullOrEmptyOrUndefined(item.started) === false && IsNullOrEmptyOrUndefined(item.ended) === false) {
                                item.duration = TimeDiffInHHMMSS(item.started, item.ended)
                                sessionStorage.removeItem(item.reportId);
                            }
                            if (item.status === 'Inprogress') {
                                if (sessionStorage.getItem(item.reportId) === null) sessionStorage.setItem(item.reportId, new Date());
                            }
                        });

                        var reportcount = data.data.filter((o) => o.readStatus === false).length
                        dispatch({ type: "unread_count", unreadcount: { count: reportcount } });
                        setReports(data.data);
                    } else {
                        setReports([])
                    }
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const downloadFile = (UserId, ReportId) => {
        setDownloading(true)

        var url = BASE_URL + 'UserReport/DownloadReport?UserId=' + UserId + '&ReportId=' + ReportId
        GetBLOB(url, props.instance)
            .then((data) => {
                setDownloading(false)
                if (IsNullOrEmptyOrUndefined(data.message) === false) {
                    CustomAlert(data.message, 'error')
                }
                else {
                    var item = reports.filter((o) => o.reportId === ReportId);
                    if (item.length > 0 && item[0].readStatus === false) {
                        item[0].readStatus = true;
                        var reportcount = reports.filter((o) => o.readStatus === false).length
                        dispatch({ type: "unread_count", unreadcount: { count: reportcount } });
                    }
                    const url = window.URL.createObjectURL(data);
                    const link = document.createElement('a');
                    link.href = url;
                    let ext = ''
                    if (data.type === 'text/csv') ext = '.csv'
                    else ext = '.xlsx'
                    let fileName = 'Accelerator Report' + ext;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                }
            })
            .catch((e) => {
                setDownloading(false)
                CustomAlert(e.message, 'error');
            });
    }
    /** API Calls End */
    const handleDownload = (Id, ReportId) => {
        setSelectedId(Id)
        downloadFile(userinfo.userid, ReportId)
    }
    const reportColumns = [
        { field: 'id', headerName: 'Id', width: 90 },
        { field: 'reportId', headerName: 'ReportId', width: 90 },
        {
            field: 'download', headerName: '', width: 60, minWidth: 60, editable: false, sortable: false, align: 'center',
            renderCell: (cell) => {
                return (
                    <>
                        {cell.row.status.toLowerCase() === 'ready' ? <Tooltip title={'Click to Download'}><IconButton size='small' onClick={() => handleDownload(cell.row.id, cell.row.reportId)}>{(selectedId === cell.row.id & downloading) ? <CircularProgress size={22} sx={{ marginTop: 0, "& .MuiCircularProgress-circle": { animationDuration: "10s" } }} /> : <FileDownloadOutlined color='success' />}</IconButton></Tooltip> : cell.row.status.toLowerCase() === 'queued' ? <Tooltip title={'Queued'}><WatchLater color='primary' /></Tooltip> : cell.row.status.toLowerCase() === 'inprogress' ? <Tooltip title={'Inprogress'}><CircularProgress size={22} color="primary" sx={{ marginTop: .6, "& .MuiCircularProgress-circle": { animationDuration: "10s" } }} /></Tooltip> : <Report color='error' />}
                    </>
                );
            },
        },
        {
            field: 'extension', headerName: '', width: 50, minWidth: 50, align: 'center',
            renderCell: (cell) => { return (<><Typography style={{ fontSize: 14, color: 'green', paddingTop: 5 }}>{cell.row.extension}</Typography></>); },
        },
        {
            field: 'sizeInByte', headerName: 'Size', width: 90,
            renderCell: (cell) => { return (<><Typography style={{ fontSize: 13, paddingTop: 6 }}>{cell.row.sizeInByte === 0 ? '' : FormatFileSize(cell.row.sizeInByte)}</Typography></>); },
        },
        {
            field: 'created', headerName: 'Created', width: 130,
            renderCell: (cell) => { return (<><Typography style={{ fontSize: 13, paddingTop: 6 }}>{IsNullOrEmptyOrUndefined(cell.row.created) ? '' : dayjs(cell.row.created).utc('z').local().format(dateformat)}</Typography></>); },
        },
        {
            field: 'name', headerName: 'Name', editable: false, sortable: false, align: 'left', flex: 1,
            renderCell: (cell) => {
                return (<>{<Typography style={{ fontSize: 13, fontWeight: cell.row.readStatus === false ? 'bold' : 'normal', paddingTop: 6 }}>{cell.row.name}</Typography>}</>);
            },
        },
        {
            field: 'timer', headerName: '', editable: false, sortable: false, align: 'left', width: 150,
            renderCell: (cell) => {
                return (
                    <>
                        {IsNullOrEmptyOrUndefined(cell.row.duration) ? '' :
                            <>
                                <Grid container>
                                    <Grid item style={{ marginTop: 5 }}><TimerOutlined color='primary' sx={{ fontSize: 18 }} /></Grid>
                                    <Grid item style={{ marginTop: 1, paddingLeft: 5 }}>{cell.row.duration}</Grid>
                                </Grid>

                            </>}
                        {
                            (IsNullOrEmptyOrUndefined(cell.row.started) === false && cell.row.status === 'Inprogress') ?
                                <>
                                    <Grid container>
                                        <Grid item style={{ marginTop: 5 }}><TimerOutlined color='primary' sx={{ fontSize: 18 }} /></Grid>
                                        <Grid item style={{ marginTop: 1, paddingLeft: 5 }}><Timer started={sessionStorage.getItem(cell.row.reportId)} /></Grid>
                                    </Grid>
                                </>
                                : <Typography style={{ fontSize: 13, marginLeft: 5 }}>{ }</Typography>
                        }
                    </>
                );
            },
        },

    ];
    const handleRefresh = () => {
        getUserReport();
    }
    return (
        <>
            <div style={{ padding: 0 }}>
                <Card variant="outlined">
                    <CardHeader style={{ padding: 8 }} title={'Report Download'} titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}><FileDownloadOutlined /></Avatar>} action={<><IconButton size='small' disabled={(loading || downloading)} variant="outlined" aria-label="Download" onClick={handleRefresh} style={{ display: 'none' }}><Refresh /></IconButton><IconButton size='small' onClick={() => props.onClose(new Date().toISOString())}><Close color='error' /></IconButton></>} />
                    <Box sx={{ height: 400, width: '100%' }}>
                        <DataGrid rowHeight={33} columnHeaderHeight={30} rows={reports} columns={reportColumns} disableColumnMenu loading={loading}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 10, }, },
                                columns: { columnVisibilityModel: { id: false, reportId: false, timer: !isMobile, created: !isMobile, sizeinbyte: !isMobile }, },
                            }}
                            pageSizeOptions={[10]} disableRowSelectionOnClick
                            sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { height: 35, minHeight: 35, border: 0 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                        />
                    </Box>
                </Card>
            </div>
        </>
    );

}
