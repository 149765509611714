import React, { useEffect, useState } from 'react';
import { Dialog, ListItemIcon, Box, Tab, ButtonGroup, Menu, LinearProgress, Select, MenuItem, FormControl, Table, TableBody, TableRow, TableCell, Checkbox, FormControlLabel, FormGroup, Card, CardContent, CardHeader, TextField, Grid, Avatar, Typography, Divider, Button, Alert, ListItemText, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import dayjs from 'dayjs';
import 'dayjs/locale/en-in';
import { ExpandMore, FileDownload, FileDownloadOutlined, Keyboard } from '@mui/icons-material';
import { CustomAlert } from '../Utilities/CustomAlert'
import { IsNullOrEmptyOrUndefined } from '../Utilities/String';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { BASE_URL } from '../Utilities/APIURLs';
// import csv from '../Images/csv6.png'
import xls from '../Images/xls.svg'
import { AddMinusDays } from '../Utilities/Date';
import { GetJSON, PostJSON } from '../Utilities/APICall';
import { UserReport } from '../UserManagement/UserReport';

export function ReportInputParams(props) {

    const dateformat = 'DD MMM YY HH:mm:ss';
    const [downloading, setDownloading] = useState(false);
    const [params, setParams] = useState(props.ReportInfo);
    const [dataset, setDataset] = useState({});
    const [queryDataType, setQueryDataType] = useState([]);
    const [htmlFormatValue, setHtmlFormatValue] = useState('');
    const [lookupRecords, setLookupRecords] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openDMenu = Boolean(anchorEl);
    const [tabValue, setTabValue] = React.useState(1);
    const [openReport, setOpenReport] = React.useState(false);
    const [reportSection, setReportSection] = React.useState(false);

    useEffect(() => {
        if (props.ReportInfo.UserId > 0 && props.ReportInfo.ReportId > 0) {
            setDataset({});
            setHtmlFormatValue('')
            if (props.ReportInfo.ParamInfo.length) {
                getLookupValues();
            }
        }
    }, [props.ReportInfo.ReportId]);

    const getLookupValues = () => {
        props.ReportInfo.ParamInfo.forEach(element => {
            if (element.dataType === 'Lookup') {
                getLookupInfoById(props.ReportInfo.ReportId, props.ReportInfo.LoginId, element.lookupListId)
            }
        });
    }
    const getLookupInfoById = (ReportId, LoginId, LookupId) => {
        setDownloading(true)
        let LookupIds = [];
        var url = BASE_URL + 'Lookup/GetByLookupId?ReportId=' + ReportId + '&LoginId=' + LoginId + '&LookupId=' + LookupId;
        GetJSON(url, props.instance)
            .then((data) => {
                setDownloading(false)
                if (IsNullOrEmptyOrUndefined(data.message) === false) {
                    CustomAlert(data.message, 'error')
                }
                else {
                    var strLookupIds = sessionStorage.getItem('LookupIds');
                    if (IsNullOrEmptyOrUndefined(strLookupIds) === false) {
                        LookupIds = JSON.parse(strLookupIds)
                        if (LookupIds.includes(LookupId) === false) {
                            setLookupRecords(prevArray => [...prevArray, { 'LookupListId': LookupId, 'LookupData': data.data, 'Connected': data.connected, "ConnectedId": data.connectedId }]);
                            LookupIds.push(LookupId)
                            sessionStorage.setItem('LookupIds', JSON.stringify(LookupIds))
                        }
                    }
                    else {
                        LookupIds.push(LookupId)
                        sessionStorage.setItem('LookupIds', JSON.stringify(LookupIds))
                        setLookupRecords(prevArray => [...prevArray, { 'LookupListId': LookupId, 'LookupData': data.data, 'Connected': data.connected, "ConnectedId": data.connectedId }]);
                    }
                }
            })
            .catch((e) => {
                setDownloading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const getLookupInfoByValue = (ReportId, LoginId, LookupId, LookupValue) => {
        setDownloading(true)
        const myLookupValue = encodeURIComponent(LookupValue);
        var url = BASE_URL + 'Lookup/GetByLookupValue?ReportId=' + ReportId + '&LoginId=' + LoginId + '&LookupId=' + LookupId + '&LookupValue=' + myLookupValue;
        GetJSON(url, props.instance)
            .then((data) => {
                setDownloading(false)
                if (IsNullOrEmptyOrUndefined(data.message) === false) {
                    CustomAlert(data.message, 'error')
                }
                else {
                    const newState = lookupRecords.map(obj => {
                        if (obj.LookupListId === LookupId) {
                            return { ...obj, LookupData: data.data };
                        }
                        return obj;
                    });
                    setLookupRecords(newState);
                }
            })
            .catch((e) => {
                setDownloading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const downloadDataInFile = (ReportType, UserId, ReportId, EmployeeId, Parameters) => {
        setDownloading(true)
        var url = BASE_URL + 'ReportMaster/DownloadReportByUser?ReportType=' + ReportType
        var datamodel = { "ReportId": ReportId, "UserId": UserId, "EmployeeId": EmployeeId, "Parameter": Parameters }
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setDownloading(false)
                if (IsNullOrEmptyOrUndefined(data.message) === false) {
                    CustomAlert(data.message, 'error')
                }
                else {
                    setOpenReport(true)
                }
            })
            .catch((e) => {
                setDownloading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const downloadDataInJson = (UserId, ReportId, EmployeeId, Parameters) => {
        setDownloading(true)
        var url = BASE_URL + 'ReportMaster/DownloadDataByUser'
        var datamodel = { "ReportId": ReportId, "UserId": UserId, "EmployeeId": EmployeeId, "Parameter": Parameters }

        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setDownloading(false)
                if (IsNullOrEmptyOrUndefined(data.message) === false) {
                    CustomAlert(data.message, 'error')
                }
                else if (props.ReportInfo.ReportFormat === 'Table') {
                    if (IsNullOrEmptyOrUndefined(data.dataset) === false) {
                        setQueryDataType(data.datatype);
                        var dataset = JSON.parse(data.dataset);
                        setDataset(dataset);
                    }
                    else {
                        CustomAlert('No record found', 'info');
                    }
                    props.onRefresh();
                }
                else if (props.ReportInfo.ReportFormat === 'Html') {
                    if (IsNullOrEmptyOrUndefined(data.dataset) === false) {
                        var oHtml = JSON.parse(data.dataset)
                        setHtmlFormatValue(Object.values(Object.values(oHtml)[0][0])[0])
                    }
                    else {
                        CustomAlert('No record found', 'info');
                    }
                    props.onRefresh();
                }
            })
            .catch((e) => {
                setDownloading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const handleDownload = (ButtonId) => {
        var isPValue = true;

        //Check missing parameter values
        if (props.ReportInfo.ParamInfo.length > 0) {
            for (let i = 0; i < props.ReportInfo.ParamInfo.length; i++) {
                let element = props.ReportInfo.ParamInfo[i];
                if (IsNullOrEmptyOrUndefined(element.dataValue) || element.dataValue.length === 0) {
                    CustomAlert('Please enter the parameter value.', 'error')
                    isPValue = false;
                    break;
                }
            }
        }

        if (props.ReportInfo.ParamInfo.length > 0) {
            for (let i = 0; i < props.ReportInfo.ParamInfo.length; i++) {
                let element = props.ReportInfo.ParamInfo[i];
                if (IsNullOrEmptyOrUndefined(element.calculated) === false) {
                    var res = replaceParamValue(props.ReportInfo.ParamInfo, element.calculated.trim())
                    if (res === 'false' || res === false) {
                        CustomAlert('Please enter the correct parameter value.', 'error')
                        isPValue = false;
                        break;
                    }

                }
            }
        }




        //Set Parameters
        var _params = JSON.parse(JSON.stringify(props.ReportInfo.ParamInfo));
        _params.forEach(item => {
            if (item.dataType === 'Yes/No') {
                if (IsNullOrEmptyOrUndefined(item.dataValue)) item.dataValue = 'No'
                else if (item.dataValue) item.dataValue = 'Yes'
                else item.dataValue = 'No'
            }
            else if (item.dataType === 'Lookup') {
                if (item.multiSelect) {
                    item.dataValue = item.dataValue.filter((o) => o !== '-All-').join(',');
                }
                else if (item.dataValue === '-All-') {
                    var arrIds = lookupRecords.filter((o) => o.LookupListId === item.lookupListId)[0].LookupData;
                    if (IsNullOrEmptyOrUndefined(arrIds) === false && arrIds.length > 0) {
                        item.dataValue = arrIds.map(function (item) { return item.id; }).filter((o) => o !== '-All-').join(',');
                    }
                }
            }
            else if (item.dataType === 'Choice') {
                if (Array.isArray(item.dataValue) === false) {
                    item.dataValue = item.dataValue;
                }
                else {
                    item.dataValue = item.dataValue.join(',');
                }
            }
        });
        //--------------
        if (isPValue && ButtonId === 'view') {
            downloadDataInJson(props.ReportInfo.UserId, props.ReportInfo.ReportId, props.ReportInfo.EmployeeId, _params)
        }
        if (isPValue && ButtonId === 'xlsx') {
            setAnchorEl(null);
            downloadDataInFile('xlsx', props.ReportInfo.UserId, props.ReportInfo.ReportId, props.ReportInfo.EmployeeId, _params)
        }
        if (isPValue && ButtonId === 'csv') {
            setAnchorEl(null);
            downloadDataInFile('csv', props.ReportInfo.UserId, props.ReportInfo.ReportId, props.ReportInfo.EmployeeId, _params)
        }
    }
    const replaceParamValue = (params, script) => {
        let counter = 1
        let word = '';
        let foundAt = false;
        let found = []
        for (const char of script) {
            if (char === '@') {
                foundAt = true;
            } else if (foundAt && (char === ' ' || char === '\n' || char === ';')) {
                var fnd = params.filter(o => o.name.toLowerCase() === '@' + word.toLowerCase().trim());
                if (fnd.length > 0) {
                    found.push(fnd[0]);
                }
                counter = counter + 1
                word = ''
                foundAt = false
            } else if (foundAt) {
                word += char; // Append characters to the word
            }
        }
        let finalScript = ''
        found.forEach(element => {
            if (element.dataType.toLowerCase() === 'date' || element.dataType.toLowerCase() === 'datetime') {
                finalScript = script.replace(element.name.toLowerCase(), "'" + element.dataValue + "'")
                script = finalScript;
            }
        });
        var res = 'false'
        if (finalScript.length > 0) {
            res = eval(finalScript)
        }
        else {
            res = 'true';
        }
        return res;
    }

    const handleFormChange = (event, index) => {
        let _params = [...props.ReportInfo.ParamInfo];
        var pObject = _params[index];
        if (pObject.dataType === 'Date' || pObject.dataType === 'DateTime') {
            if (isNaN(event.$d) === false) {
                pObject.dataValue = event.$d.toISOString()
            }
            else {
                pObject.dataValue = null;
            }
            setParams(_params)
        }
        else if (pObject.dataType === 'Yes/No') {
            pObject.dataValue = event.target.checked;
            setParams(_params)
        }
        else if (pObject.dataType === 'Lookup') {
            if (pObject.multiSelect) {
                if (event.target.value.includes('-All-')) {
                    var LookupItems = lookupRecords.filter((o) => o.LookupListId === pObject.lookupListId).length > 0 && lookupRecords.filter((o) => o.LookupListId === pObject.lookupListId)[0].LookupData
                    pObject.dataValue = LookupItems.map(function (item) { return item.id; });
                }
                else if (IsNullOrEmptyOrUndefined(pObject.dataValue) === false && pObject.dataValue.includes('-All-')) {
                    pObject.dataValue = [];
                }
                else {
                    pObject.dataValue = event.target.value;
                }
            }
            else {
                pObject.dataValue = event.target.value;
            }

            setParams(_params)
            //Connected Lookup
            var lookupObject = lookupRecords.filter((o) => o.ConnectedId === pObject.lookupListId).length > 0 && lookupRecords.filter((o) => o.ConnectedId === pObject.lookupListId)[0]
            if (lookupObject.Connected) {
                getLookupInfoByValue(props.ReportInfo.ReportId, props.ReportInfo.LoginId, lookupObject.LookupListId, event.target.value);
            }
        }
        else if (pObject.dataType === 'Number') {

            if (IsNullOrEmptyOrUndefined(pObject.minNumber) === false && event.target.value < Number(pObject.minNumber)) {
                CustomAlert('Value should not be less than Minimum value', 'error')
            }
            else if (IsNullOrEmptyOrUndefined(pObject.maxNumber) === false && event.target.value > Number(pObject.maxNumber)) {
                CustomAlert('Value should not be greater than Maximum value', 'error')
            }
            else {
                pObject.dataValue = event.target.value;
                setParams(_params)
            }
        }
        else {
            pObject.dataValue = event.target.value;
            setParams(_params)
        }
    }
    function createMarkup() {
        return { __html: htmlFormatValue };
    }
    const renderControl = (item, index) => {
        return (
            <Grid item>
                {
                    (item.dataType === 'String') &&
                    <>
                        <Typography variant='body2'>{item.name.substring(1, item.name.length)}</Typography>
                        <TextField size='small' variant="outlined" sx={{ width: 220, paddingBottom: '5px' }} inputProps={{ maxLength: IsNullOrEmptyOrUndefined(item.maxLength) ? 25 : item.maxLength, style: { fontSize: 13 } }} value={item.dataValue} onChange={(event) => handleFormChange(event, index)} />
                    </>
                }
                {
                    (item.dataType === 'Number') &&
                    <>
                        <Typography variant='body2' >{item.name.substring(1, item.name.length)}</Typography>
                        <TextField size='small' variant="outlined" sx={{ width: 220, paddingBottom: '5px' }} inputProps={{ maxLength: 25, style: { fontSize: 13 } }} type="number" value={item.dataValue} onChange={(event) => handleFormChange(event, index)} />
                    </>
                }
                {
                    (item.dataType === 'Date') &&
                    <>
                        <Typography variant='body2'>{item.name.substring(1, item.name.length)}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker slotProps={{ textField: { InputProps: { size: 'small' } } }} sx={{ width: 220, '& .MuiInputBase-input, & .MuiOutlinedInput-input': { fontSize: 14, paddingLeft: '8px', paddingRight: '6px' }, }} format={IsNullOrEmptyOrUndefined(item.format) ? 'DD-MM-YYYY' : item.format} minDate={IsNullOrEmptyOrUndefined(item.dateRange) ? undefined : dayjs(AddMinusDays(new Date(), Number(item.dateRange), '-'))} maxDate={IsNullOrEmptyOrUndefined(item.dateRange) ? undefined : dayjs(AddMinusDays(new Date(), Number(item.dateRange), '+'))} value={dayjs(item.dataValue)} onChange={(event) => handleFormChange(event, index)} />
                        </LocalizationProvider>
                    </>

                }
                {
                    (item.dataType === 'DateTime') &&
                    <>
                        <Typography variant='body2'>{item.name.substring(1, item.name.length)}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker slotProps={{ textField: { size: 'small' } }} sx={{ width: 220, '& .MuiInputBase-input, & .MuiOutlinedInput-input': { fontSize: 14, paddingLeft: '8px', paddingRight: '6px' }, }} format={IsNullOrEmptyOrUndefined(item.format) ? 'DD-MM-YYYY HH:mm:ss' : item.format} ampm={false} container="inline" value={dayjs(item.dataValue)} onChange={(event) => handleFormChange(event, index)} />
                        </LocalizationProvider>
                    </>
                }
                {
                    (item.dataType === 'Time') &&
                    <>
                        <Typography variant='body2'>{item.name.substring(1, item.name.length)}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker slotProps={{ textField: { size: 'small' } }} sx={{ width: 220, '& .MuiInputBase-input, & .MuiOutlinedInput-input': { paddingLeft: '6px', paddingRight: '6px', paddingTop: '13px', paddingBottom: '13px' }, }} format="HH:mm:ss" ampm={false} container="inline" value={dayjs(item.dataValue)} onChange={(event) => handleFormChange(event, index)} />
                        </LocalizationProvider>
                    </>
                }
                {
                    (item.dataType === 'Yes/No') &&
                    <>
                        <Typography variant='body2'>{item.name.substring(1, item.name.length)}</Typography>
                        <FormGroup style={{ marginTop: -3 }} ><FormControlLabel control={<Checkbox defaultChecked={item.dataValue} />} label={(item.dataValue === '' || item.dataValue === false) ? 'No' : 'Yes'} onChange={(event) => handleFormChange(event, index)} /></FormGroup>
                    </>
                }
                {
                    (item.dataType === 'Choice' && item.multiSelect === false) &&
                    <>
                        <Typography variant='body2'>{item.name.substring(1, item.name.length)}</Typography>
                        <FormControl sx={{ width: 220 }} size="small" required>
                            <Select displayEmpty style={{ fontSize: 14 }} value={item.dataValue} onChange={(event) => handleFormChange(event, index)}>
                                {
                                    item.items.split('\n').filter(Boolean).map((name, index) => (<MenuItem style={{ fontSize: 13 }} key={name + index} value={name.trim()}>{name.trim()}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                    </>
                }
                {
                    (item.dataType === 'Choice' && item.multiSelect) &&
                    <>
                        <Typography variant='body2'>{item.name.substring(1, item.name.length)}</Typography>
                        <FormControl sx={{ width: 220 }} size="small" required>
                            <Select multiple style={{ fontSize: 14 }} value={item.dataValue} onChange={(event) => handleFormChange(event, index)} renderValue={(selected) => selected.join(', ')}>
                                {
                                    item.items.split('\n').filter(Boolean).map((name, index) => (
                                        <MenuItem dense={true} style={{ fontSize: 13, paddingTop: 0, paddingBottom: 0 }} key={name.id + '200'} value={name.trim()}>
                                            <Checkbox checked={item.dataValue.indexOf(name.trim()) > -1} size="small" sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />
                                            <ListItemText primary={name.trim()} />
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </>
                }
                {
                    (item.dataType === 'Lookup' && item.multiSelect === false) &&
                    <>
                        <Typography variant='body2'>{item.name.substring(1, item.name.length)}</Typography>
                        <FormControl sx={{ width: 220 }} size="small" required>
                            <Select displayEmpty style={{ fontSize: 14 }} defaultValue={item.dataValue} value={item.dataValue} onChange={(event) => handleFormChange(event, index)}>
                                {
                                    (lookupRecords.filter((o) => o.LookupListId === item.lookupListId).length > 0) && lookupRecords.filter((o) => o.LookupListId === item.lookupListId)[0].LookupData.map((name) => (<MenuItem style={{ fontSize: 13 }} key={name.id} value={name.id}>{name.label}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                    </>
                }
                {
                    (item.dataType === 'Lookup' && item.multiSelect) &&
                    <>
                        <Typography variant='body2'>{item.name.substring(1, item.name.length)}</Typography>
                        <FormControl sx={{ width: 220 }} size="small" required>
                            <Select multiple style={{ fontSize: 14 }} defaultValue={item.dataValue} value={item.dataValue} onChange={(event) => handleFormChange(event, index)} renderValue={(selected) => selected.join(', ')}>
                                {
                                    (lookupRecords.filter((o) => o.LookupListId === item.lookupListId).length > 0) && lookupRecords.filter((o) => o.LookupListId === item.lookupListId)[0].LookupData.map((name) => (
                                        <MenuItem dense={true} style={{ fontSize: 13, paddingTop: 0, paddingBottom: 0 }} key={name.id + '200'} value={name.id}>
                                            <Checkbox checked={item.dataValue.indexOf(name.id) > -1} size="small" sx={{ '& .MuiSvgIcon-root': { fontSize: 18 } }} />
                                            <ListItemText primary={name.label} />
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                    </>
                }
            </Grid>
        )
    }
    const renderParams = () => {
        if (IsNullOrEmptyOrUndefined(props) === false && props.ReportInfo.ParamInfo.length > 0) {
            return (

                <Grid container spacing={1} style={{ padding: 5 }}>
                    {
                        props.ReportInfo.ParamInfo.map((element, index) => (
                            renderControl(props.ReportInfo.ParamInfo[index], index)
                        ))
                    }
                </Grid>
            )
        }
        else
            return (
                <>
                    <Grid container spacing={1}>
                        <Grid item xs={12} style={{ padding: 0 }}>
                            <Alert icon={false} variant='standard' style={{ padding: 0, paddingLeft: 8, borderRadius: 0, justifyContent: 'center', textAlign: 'center' }}>This report does not have any parameter/s.Please click View/Download button.</Alert>
                        </Grid>
                    </Grid>
                </>
            )
    }
    const renderDataset = () => {
        return (
            <>
                <TabContext value={tabValue}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={(e, value) => setTabValue(value)} sx={{ minHeight: 30, height: 30 }}>
                            {
                                Object.keys(dataset).map((table, index) => (
                                    <Tab style={{ textTransform: 'none', height: 30, minHeight: 30 }} label={table} value={index + 1} />
                                ))
                            }
                        </TabList>
                    </Box>
                    {
                        Object.keys(dataset).map((table, index) => (
                            <TabPanel value={index + 1} style={{ padding: 0 }}>
                                <Table size="small">
                                    <TableBody>
                                        <TableRow style={{ height: 30 }}>
                                            {
                                                Object.keys(dataset[table][0]).map((col, ind) => (<TableCell key={'colheader' + ind} style={{ fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>{col}</TableCell>))
                                            }
                                        </TableRow>
                                        {
                                            dataset[table].map((row, ind) => (
                                                <TableRow key={'row' + ind + 2} >
                                                    {
                                                        Object.keys(dataset[table][0]).map((col, ind) => (<TableCell key={'col' + ind + 3} style={{ fontSize: 12, textAlign: 'left', }}>{(queryDataType.length > 0 && queryDataType.filter((o) => o.key === table).length > 0 && queryDataType.filter((o) => o.key === table).filter(e => e.value === col).length > 0 && IsNullOrEmptyOrUndefined(row[col]) === false) ? dayjs(row[col]).format(dateformat) : row[col]}</TableCell>))
                                                    }
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            </TabPanel>
                        ))
                    }
                </TabContext>
            </>
        )
    }
    const handleCloseDialog = () => {
        setOpenReport(false)
        setReportSection(false)
    }
    const handleReportSection = () => {
        setReportSection(true);
    }
    return (
        <>
            <Card variant="outlined" style={{ height: window.innerHeight - 80 }}>
                <CardHeader style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 8 }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}><Keyboard fontSize='22' /></Avatar>} title={<>{'Download'}<span style={{ fontSize: 15, fontWeight: 550, paddingLeft: 4 }}>{': ' + props.ReportInfo.ReportName}</span></>}
                    action={
                        <div style={{ paddingRight: 2, paddingTop: 1 }}>
                            <ButtonGroup color="primary" disableElevation>
                                <Button variant="outlined" disabled={downloading} size='small' color='primary' sx={{ textTransform: 'none', height: 30, borderColor: 'silver', fontWeight: 600 }} onClick={() => handleDownload('view')}>View</Button>
                                {(props.ReportInfo.DownloadFormat === 'Xlsx' || props.ReportInfo.DownloadFormat === 'Csv and Xlsx') && <Button variant="outlined" disabled={downloading} size='small' color='primary' startIcon={<FileDownloadOutlined />} sx={{ textTransform: 'none', height: 30, fontWeight: 600, width: 70 }} onClick={() => handleDownload('xlsx')}>Excel</Button>}
                                {(props.ReportInfo.DownloadFormat === 'Csv' || props.ReportInfo.DownloadFormat === 'Csv and Xlsx') && <Button variant="outlined" disabled={downloading} size='small' color='primary' startIcon={<FileDownloadOutlined />} sx={{ textTransform: 'none', height: 30, fontWeight: 600, width: 70 }} onClick={() => handleDownload('csv')}>Csv</Button>}

                                {/* {props.ReportInfo.ReportFormat === 'Table' && <Button variant="outlined" disabled={downloading} size='small' sx={{ textTransform: 'none', height: 30 }} endIcon={<ExpandMore />} onClick={(e) => setAnchorEl(e.currentTarget)}>Export</Button>} */}
                            </ButtonGroup>
                        </div>
                    }
                    titleTypographyProps={{ variant: 'body2' }} />

                <Divider />
                {downloading ? <LinearProgress style={{ height: 8 }} /> : <div style={{ height: 8 }}></div>}
                <Grid container>
                    <Grid item xs={12}>
                        <CardContent style={{ minHeight: 140, padding: 0, paddingLeft: 0 }}>
                            {renderParams()}
                        </CardContent>
                    </Grid>
                </Grid>
                <Typography style={{ fontSize: 12, paddingLeft: 5 }} color='text.secondary'>Max 100 record will only be visible on screen.</Typography>
                <Grid container spacing={1} style={{ paddingLeft: 3, paddingRight: 3, paddingBottom: 3 }}>
                    <Grid item xs={12} >
                        <Card variant='outlined' style={{ overflowY: 'scroll', overflowX: 'scroll', minHeight: window.innerHeight - 283, maxHeight: window.innerHeight - 283, padding: 0 }}>
                            {
                                (Object.keys(dataset).length === 0 && IsNullOrEmptyOrUndefined(htmlFormatValue)) &&
                                <div style={{ justifyContent: 'center', textAlign: 'center' }}><Typography>No record found.</Typography></div>
                            }
                            {
                                (IsNullOrEmptyOrUndefined(htmlFormatValue) === false) &&
                                <div dangerouslySetInnerHTML={createMarkup()} style={{ padding: 6 }} />
                            }
                            {
                                (Object.keys(dataset).length === 1) &&
                                <Table size="small" style={{ overflowY: 'scroll', overflowX: 'scroll', textAlign: 'left', width: '100%', borderCollapse: 'separate' }}>
                                    <TableBody>
                                        <TableRow style={{ height: 30 }}>
                                            {
                                                Object.keys(dataset[Object.keys(dataset)[0]][0]).map((col, ind) => (<TableCell key={'colheader' + ind} style={{ fontSize: 12, textAlign: 'left', fontWeight: 'bold' }}>{col}</TableCell>))
                                            }
                                        </TableRow>
                                        {
                                            dataset[Object.keys(dataset)[0]].map((row, ind) => (
                                                <TableRow key={'row' + ind + 2} >
                                                    {
                                                        Object.keys(dataset[Object.keys(dataset)[0]][0]).map((col, ind) => (<TableCell key={'col' + ind + 3} style={{ fontSize: 12, textAlign: 'left', }}>{(queryDataType.length > 0 && queryDataType.filter((o) => o.key === Object.keys(dataset)[0]).length > 0 && queryDataType.filter((o) => o.key === Object.keys(dataset)[0]).filter(e => e.value === col).length > 0 && IsNullOrEmptyOrUndefined(row[col]) === false) ? dayjs(row[col]).format(dateformat) : row[col]}</TableCell>))
                                                    }
                                                </TableRow>
                                            ))
                                        }
                                    </TableBody>
                                </Table>
                            }
                            {
                                (Object.keys(dataset).length > 1) &&
                                renderDataset()
                            }

                        </Card>
                    </Grid>
                </Grid>
            </Card >
            <Menu elevation={0} sx={{ '& .MuiPaper-root': { border: '1px solid silver' } }} anchorEl={anchorEl} open={openDMenu} onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={() => handleDownload(2)} style={{ fontSize: 13 }}><ListItemIcon><img src={xls} style={{ width: 22, height: 22 }} /></ListItemIcon>Export to Excel</MenuItem>
                {/* <MenuItem disabled onClick={() => handleDownload(3)} style={{ fontSize: 13 }}><ListItemIcon><img src={csv} style={{ width: 22, height: 22 }} /></ListItemIcon>Export to CSV</MenuItem> */}
            </Menu>

            <Dialog fullWidth={true} maxWidth={'md'} onClose={handleCloseDialog} open={openReport} disableEscapeKeyDown={true}>
                {reportSection === false &&
                    <>

                        <DialogContent>
                            <Typography>Your request for download the selected report has been submitted and you can keep working while this runs in the background. It will shortly be available to download in Download section.</Typography>
                            Click here to go to <Button size='small' variant="text" disableElevation color="primary" style={{ textTransform: 'none', fontWeight: 'bold', fontSize: 16 }} onClick={handleReportSection}>Download</Button>
                        </DialogContent>
                    </>
                }
                {reportSection && <UserReport instance={props.instance} onClose={handleCloseDialog} />}
            </Dialog >
        </>
    );

}
