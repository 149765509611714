import React, { useEffect, useState, useRef } from 'react';
import { FormGroup, FormControlLabel, Checkbox, Tooltip, Typography, FormControl, InputLabel, Select, MenuItem, TextField, CardContent, CardHeader, Divider, Grid, Button, Box, IconButton, Tab, Card, Avatar, Collapse, CardActions } from '@mui/material';
import dayjs from 'dayjs';
import 'dayjs/locale/en-in';
import { ExpandMore, Settings, Delete, FlashAutoTwoTone } from '@mui/icons-material';
import { CustomAlert } from '../Utilities/CustomAlert'
import { IsNullOrEmptyOrUndefined, ValidateInput, ValidateEmail } from '../Utilities/String';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { styled } from '@mui/material/styles';
import Excel from 'exceljs';
import { BASE_URL } from '../Utilities/APIURLs';
import MuiAccordion from '@mui/material/Accordion';
import { DataGrid } from '@mui/x-data-grid';
import { AddMinusDays, LocalTimeZone } from '../Utilities/Date';
import { LoadingButton } from '@mui/lab';
import { GetJSON, PostJSON } from '../Utilities/APICall';

export function ReportSettings(props) {

    const [id, setId] = useState(0);
    const [tabValue, setTabValue] = React.useState('1');
    const [loading, setLoading] = React.useState(false);
    const [connecting, setConnecting] = React.useState(false);


    const [frequency, setFrequency] = useState('');
    const [frequencyValue, setFrequencyValue] = useState('');
    const [recurrence, setRecurrence] = useState([]);

    const [emailTo, setEmailTo] = useState('');
    const [emailCc, setEmailCc] = useState('');
    const [emailSubject, setEmailSubject] = React.useState('');
    const [emailBody, setEmailBody] = React.useState('');

    const [storageType, setStorageType] = React.useState('');
    const [storageConnection, setStorageConnection] = React.useState('');
    const [storageContainer, setStorageContainer] = React.useState('');

    const [sheets, setSheets] = React.useState([]);
    const [sheetname, setSheetname] = React.useState('');
    const [fileName, setFilename] = React.useState('');
    const [fileContent, setFileContent] = React.useState(null);

    const [instructionTemplate, setInstructionTemplate] = useState('');
    const [recurringInstruction, setRecurringInstruction] = useState('');

    const [attachementInEmail, setAttachementInEmail] = useState(false);
    const [SFTPServer, setSFTPServer] = useState('');
    const [SFTPUserId, setSFTPUserId] = useState('');
    const [SFTPPassword, setSFTPPassword] = useState('');
    const [SFTPFolder, setSFTPFolder] = useState('');

    const [expand, setExpand] = useState(true);
    const [transformIcon, setTransformIcon] = useState('rotate(180deg)');
    const transition = 'all 1s';
    const [expandAcc, setExpandAcc] = useState(true);

    const [monthday, setMonthday] = React.useState(-1);
    const [weekday, setWeekday] = React.useState(-1);
    const [hour, setHour] = React.useState('00');
    const [minute, setMinute] = React.useState('00');

    const COLORS = ['#9F0000', '#001E51', '#027D00']
    const MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
    const HOURS = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23']
    const MINUTES = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']
    const WEEKDAYS = [{ name: 'Sunday', value: 0 }, { name: 'Monday', value: 1 }, { name: 'Tuesday', value: 2 }, { name: 'Wednesday', value: 3 }, { name: 'Thrusday', value: 4 }, { name: 'Friday', value: 5 }, { name: 'Saturday', value: 6 }]
    const dataFetchedRef = useRef(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpandAcc(isExpanded ? panel : false);
    };

    useEffect(() => {
        if (props.UserId > 0 && props.ReportId > 0 && dataFetchedRef.current === false) {
            dataFetchedRef.current = true;
            getSetting(props.UserId, props.ReportId);
            getRecurrence(props.UserId, props.ReportId);
        }
    }, [props.UserId, props.ReportId]);


    /** API Calls */
    const getSetting = (UserId, ReportId) => {
        setLoading(true)
        var url = BASE_URL + 'ReportSetting/Get?UserId=' + UserId + '&ReportId=' + ReportId;
        GetJSON(url, props.instance).then(data => {
            setLoading(false)
            if (IsNullOrEmptyOrUndefined(data.setting) === false) {
                setId(data.setting.id)

                setEmailTo(data.setting.emailTo)
                setEmailCc(data.setting.emailCc)
                setEmailSubject(data.setting.emailSubject)
                setEmailBody(data.setting.emailBody)
                setAttachementInEmail(data.setting.addAttachmentInEmail)

                setStorageType(data.setting.storageType)
                setStorageConnection(data.setting.storageConnection)
                setStorageContainer(data.setting.storageContainer)

                setSFTPServer(data.setting.sftpServer)
                setSFTPUserId(data.setting.sftpUserId)
                setSFTPPassword(data.setting.sftpPassword)
            }
            else {
                ResetValue()
            }
            if (IsNullOrEmptyOrUndefined(data.recurrence) === false) {
                setRecurrenceTable(data.recurrence)
            }

        })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const getRecurrence = (UserId, ReportId) => {
        setLoading(true)
        var url = BASE_URL + 'ReportSetting/GetRecurrence?UserId=' + UserId + '&ReportId=' + ReportId;
        GetJSON(url, props.instance).then(data => {
            setLoading(false)
            dataFetchedRef.current = false
            if (IsNullOrEmptyOrUndefined(data.setting) === false) {
                setRecurrenceTable(data.setting);
            }
        })
            .catch((e) => {
                dataFetchedRef.current = false
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const setRecurrenceTable = (data) => {
        data.forEach(element => {
            var userinput = element.userinput !== null ? JSON.parse(element.userinput) : '';
            let localTime = ''
            let utcTime = ''
            if (IsNullOrEmptyOrUndefined(userinput) === false) {
                localTime = (userinput.monthday === -1 ? '' : userinput.monthday) + '' + (userinput.weekday === -1 ? '' : getWeedDayById(userinput.weekday)) + ' ' + userinput.hour + ':' + userinput.minute + ' - ' + '' + userinput.localtimezone
                element.localTime = localTime
            }

            utcTime = (element.monthDay === -1 ? '' : element.monthDay.toString().padStart(2, '0')) + '' + (element.weekDay === -1 ? '' : getWeedDayById(element.weekDay)) + ' ' + element.hour + ':' + element.minute + ' - UTC(Coordinated Universal Time)'
            element.utcTime = utcTime
            element.localTime = localTime;
        });
        setRecurrence(data);
    }
    const addRecurrence = () => {
        if (IsNullOrEmptyOrUndefined(frequency)) {
            CustomAlert('Please select Frequency', 'error')
            return;
        }
        if (frequency === 'Weekly' && weekday === -1) {
            CustomAlert('Please select Weekday', 'error')
            return;
        }
        if (frequency === 'Monthly' && monthday === -1) {
            CustomAlert('Please select Monthday', 'error')
            return;
        }
        //Convert time to UTC
        var Today = new Date();
        var tHour = 0
        var tMinute = 0
        let tWeekday = weekday
        let tMonthday = monthday

        if (frequency === 'Daily') {
            var tDate = Today.getFullYear() + '-' + Today.getMonth().toString().padStart(2, '0') + '-' + Today.getDate().toString().padStart(2, '0');
            var sTime = hour.padStart(2, '0') + ':' + minute.padStart(2, '0') + ':00'
            var tDatetime = new Date(tDate + 'T' + sTime);
            const tUTCTime = new Date(tDatetime.toUTCString().slice(0, -4));
            tHour = tUTCTime.getHours()
            tMinute = tUTCTime.getMinutes()
        }
        else if (frequency === 'Weekly') {
            var lDate = AddMinusDays(Today, 10, '-')
            var sDate;
            for (var d = lDate; d <= Today; d.setDate(d.getDate() + 1)) {
                if (d.getDay() === weekday) {
                    sDate = d;
                    break;
                }
            }
            var tDate = new Date(sDate.getFullYear(), sDate.getMonth(), sDate.getDate(), hour, minute, 0);
            const tUTCTime = new Date(tDate.toUTCString().slice(0, -4));
            tHour = tUTCTime.getHours()
            tMinute = tUTCTime.getMinutes()
            tWeekday = tUTCTime.getDay()
        }
        else if (frequency === 'Monthly') {
            var sDate = new Date(Today.getFullYear(), Today.getMonth(), monthday, hour, minute, 0)
            const tUTCTime = new Date(sDate.toUTCString().slice(0, -4));
            tHour = tUTCTime.getHours()
            tMinute = tUTCTime.getMinutes()
            tMonthday = tUTCTime.getDate()
        }

        var userSelection = { 'monthday': monthday, 'weekday': weekday, 'hour': hour, 'minute': minute, 'localtimezone': LocalTimeZone() }

        setLoading(true)
        var url = BASE_URL + 'ReportSetting/AddRecurrence'
        var datamodel = { "Id": id, "UserId": props.UserId, "ReportId": props.ReportId, "Frequency": frequency, "MonthDay": tMonthday, "WeekDay": tWeekday, "Hour": tHour, "Minute": tMinute, "UserInput": JSON.stringify(userSelection) }
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.message === 'success') {
                    setRecurrenceTable(data.data)
                    CustomAlert('Schedule has been successfuly added', 'success')
                    setFrequency('')
                    ResetRecurrence()
                }
                else {
                    if (IsNullOrEmptyOrUndefined(data.message) === false)
                        CustomAlert(data.message, 'error');
                }
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const handleDeleteRecur = (id) => {
        if (window.confirm("Are you sure you want to delete the selected schedule?") === true) {
            setLoading(true)
            var url = BASE_URL + 'ReportSetting/DeleteRecurrence?Id=' + id + '&UserId=' + props.UserId + '&ReportId=' + props.ReportId;
            GetJSON(url, props.instance)
                .then(data => {
                    setLoading(false)
                    if (data.message === 'success') {
                        setRecurrenceTable(data.data)
                        CustomAlert('Schedule has been deleted', 'success');
                    }
                })
                .catch((e) => {
                    setLoading(false)
                    CustomAlert(e.message, 'error');
                });
        }
    }
    const saveSetting = () => {
        setLoading(true)
        var url = BASE_URL + 'ReportSetting/InsertAndUpdate'
        var datamodel = { "Id": id, "UserId": props.UserId, "ReportId": props.ReportId, "ReportType": props.ReportType, "Frequency": frequency, "FrequencyValue": frequencyValue, "EmailTo": emailTo, "EmailSubject": emailSubject, "EmailBody": emailBody, "StorageType": storageType, "StorageConnection": storageConnection, "StorageContainer": storageContainer, "ModifiedBy": props.UserId }
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.message === 'Success')
                    CustomAlert('Setting has been successfuly updated', 'success')
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const handleEmailSave = () => {

        var error = false;
        if (IsNullOrEmptyOrUndefined(emailTo)) {
            CustomAlert('Please valid email address', 'error')
            return;
        }
        var emailt = emailTo.split(';')
        for (var i = 0; i < emailt.length; i++) {
            if (IsNullOrEmptyOrUndefined(emailt[i].trim())) continue;
            if (ValidateEmail(emailt[i].trim()) === false) {
                CustomAlert('Please enter valid EmailTo address', 'error')
                error = true;
                break;
            }
        }
        if (error === true) return;

        if (IsNullOrEmptyOrUndefined(emailCc) === false) {
            var emailc = emailCc.split(';')
            for (var i = 0; i < emailc.length; i++) {
                if (IsNullOrEmptyOrUndefined(emailc[i].trim())) continue;
                if (ValidateEmail(emailc[i].trim()) === false) {
                    CustomAlert('Please enter valid EmailCc address', 'error')
                    error = true;
                    break;
                }
            }
        }
        if (error === true) return;

        if (IsNullOrEmptyOrUndefined(emailSubject) || ValidateInput(emailSubject) === false) {
            CustomAlert('Please enter Subject', 'error')
            return;
        }
        if (IsNullOrEmptyOrUndefined(emailBody)) {
            CustomAlert('Please enter Body', 'error')
            return;
        }
        if (attachementInEmail && props.ReportFormat === 'Html') {
            if (emailBody.indexOf('{0}') === -1) {
                CustomAlert('Please enter {0} in your email body to add dynamic HTML content', 'error')
                return;
            }
        }
        setLoading(true)
        var url = BASE_URL + 'ReportSetting/UpdateEmailSetting'
        var datamodel = { "Id": id, "UserId": props.UserId, "ReportId": props.ReportId, "ReportType": props.ReportType, "EmailTo": emailTo, "EmailCc": emailCc, "EmailSubject": emailSubject, "EmailBody": emailBody, "AddAttachmentInEmail": attachementInEmail }
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.message === 'success')
                    CustomAlert('Setting has been successfuly updated', 'success')
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const handleEmailRemove = () => {
        setLoading(true)

        var url = BASE_URL + 'ReportSetting/UpdateEmailSetting'
        var datamodel = { "Id": id, "UserId": props.UserId, "ReportId": props.ReportId, "ReportType": props.ReportType, "EmailTo": "", "EmailCc": "", "EmailSubject": "", "EmailBody": "", "AddAttachmentInEmail": false }
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.message === 'success') {
                    setEmailTo('')
                    setEmailCc('')
                    setEmailSubject('')
                    setEmailBody('')
                    setAttachementInEmail(false)
                    CustomAlert('Setting has been successfuly updated', 'success')
                }
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const handleStorageSave = () => {

        if (storageType === 'AzureStorage') {
            if (IsNullOrEmptyOrUndefined(storageConnection) || IsNullOrEmptyOrUndefined(storageContainer)) {
                CustomAlert('Please select storage Connection and Container', 'error')
                return;
            }
        }

        if (storageType === 'SFTP-Credential') {
            if (IsNullOrEmptyOrUndefined(SFTPServer) || ValidateInput(SFTPServer) === false || IsNullOrEmptyOrUndefined(SFTPUserId) || ValidateInput(SFTPUserId) === false || IsNullOrEmptyOrUndefined(SFTPPassword) || IsNullOrEmptyOrUndefined(SFTPFolder)) {
                CustomAlert('Please fill SFTP details with no special char in Server and UserId', 'error')
                return;
            }
        }
        var datamodel = {}
        if (IsNullOrEmptyOrUndefined(storageType)) {
            datamodel = { "Id": id, "UserId": props.UserId, "ReportId": props.ReportId, "ReportType": props.ReportType, "StorageType": "", "StorageConnection": "", "StorageContainer": "", "SFTPServer": "", "SFTPUserId": "", "SFTPPassword": "", "SFTPFolder": "" }
        }
        else {
            datamodel = { "Id": id, "UserId": props.UserId, "ReportId": props.ReportId, "ReportType": props.ReportType, "StorageType": storageType, "StorageConnection": storageConnection, "StorageContainer": storageContainer, "SFTPServer": SFTPServer, "SFTPUserId": SFTPUserId, "SFTPPassword": SFTPPassword, "SFTPFolder": SFTPFolder }
        }
        setLoading(true)
        var url = BASE_URL + 'ReportSetting/UpdateStorageSetting'
        PostJSON(url, datamodel, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.message === 'success')
                    CustomAlert('Setting has been successfuly updated', 'success')
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    const handleTestConnection = () => {
        if (IsNullOrEmptyOrUndefined(storageType) || IsNullOrEmptyOrUndefined(SFTPServer) || IsNullOrEmptyOrUndefined(SFTPUserId) || IsNullOrEmptyOrUndefined(SFTPPassword)) {
            CustomAlert('Please fill SFTP details', 'error')
            return;
        }

        setConnecting(true)
        var url = BASE_URL + 'ReportSetting/VerifySFTPConnection?Server=' + SFTPServer + '&UserId=' + SFTPUserId + '&Password=' + SFTPPassword
        GetJSON(url, props.instance)
            .then((data) => {
                setConnecting(false)
                if (data.message === 'success')
                    CustomAlert('Connection has been estabilished', 'success')
                else
                    CustomAlert(data.message, 'error')
            })
            .catch((e) => {
                setConnecting(false)
                CustomAlert(e.message, 'error');
            });
    }
    const handleSave = () => {
        // var isvalidate = validateReportForm()
        // if (isvalidate === false) return;
        saveSetting();
    }
    const handleDelete = () => {

    }
    /** API Calls End */
    const validateReportForm = () => {
        if (IsNullOrEmptyOrUndefined(frequency)) {
            CustomAlert('Please select frequency', 'error')
            return false;
        }
        if (IsNullOrEmptyOrUndefined(frequencyValue) || frequencyValue.length > 500 || frequencyValue.length <= 0) {
            CustomAlert('Please enter frequency value', 'error')
            return false;
        }
    }
    const ResetValue = () => {
        setId(0)
        setFrequency('')
        setFrequencyValue('')
        setEmailTo('')
        setEmailCc('')
        setEmailSubject('')
        setEmailBody('')
        setStorageType('')
        setStorageConnection('')
        setStorageContainer('')
    }
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
        if (newValue === '2') {
            setInstructionTemplate('Date get uploaded on the selected sheet');
        }

    };
    const handleSheetname = (e) => {
        setSheetname(e.target.value);
        setInstructionTemplate('Date get uploaded on the selected sheet : ' + e.target.value);
    }
    const handleFileChange = (e) => {
        setFilename(e.target.files[0].name);
        setFileContent(e.target.files[0]);
        let _sheet = []
        const wb = new Excel.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(e.target.files[0]);
        reader.onload = () => {
            const buffer = reader.result;
            wb.xlsx.load(buffer).then((workbook) => {
                workbook.eachSheet((sheet, id) => {
                    _sheet.push(sheet._name);
                });
                setSheets(_sheet);
            });
        };

    }
    const handleExpandReport = () => {
        if (expand === false) {
            setExpand(true)
            setTransformIcon('rotate(180deg)')
        }
        else {
            setExpand(false)
            setTransformIcon('rotate(0deg)')
        }
    }
    const handleFrequency = (e) => {
        setFrequency(e.target.value)
        handleRecurringInstruction(e.target.value);
        ResetRecurrence();
    }
    const handleRecurringInstruction = (val) => {
        if (val === 'Hourly') {
            setRecurringInstruction('Enter minute/s like 5,8,55. The report will executed three times in an hours as per example once reach the minute/s.')
        } else if (val === 'Daily') {
            setRecurringInstruction('Enter time in 24 hour format like 02:30, 21:05. The report will executed two times daily as per example once reach the time.')
        } else if (val === 'Weekly') {
            setRecurringInstruction('Enter time in day and 24 hour format like 1-11:29,23:34;5-21:05.The report will executed two times on Monday and one times on Friday as per example once reaches the day and time.')
        } else if (val === 'Monthly') {
            setRecurringInstruction('Enter time in 24 hour format like 02:30, 21:05. The report will executed two times daily as per example once reach the time.')
        } else {
            setRecurringInstruction('')
        }
    }
    const handleAddRecurrence = () => {
        addRecurrence();
    }
    const ResetRecurrence = () => {
        setMonthday(-1)
        setWeekday(-1)
        setHour('00')
        setMinute('00')
    }
    const getWeedDayById = (weekday) => {
        var dayvalue = ''
        var dayname = WEEKDAYS.filter(o => o.value === weekday)
        if (dayname.length > 0) {
            dayvalue = dayname[0].name
        }
        else {
            dayvalue = ''
        }
        return dayvalue
    }
    const columns = [
        { field: 'id', headerName: 'Id', width: 60, sortable: true },
        {
            field: 'frequencyIcon', headerName: '', width: 40,
            renderCell: (cell) => {
                return (<><Avatar sx={{ marginTop: 1, fontSize: 14, width: 28, height: 28, bgcolor: cell.row.frequency === 'Daily' ? COLORS[0] : cell.row.frequency === 'Monthly' ? COLORS[1] : COLORS[2] }}>{cell.row.frequency.substring(0, 1)}</Avatar></>);
            },
        },
        { field: 'frequency', headerName: 'Frequency', width: 120, sortable: true },
        {
            field: 'dummy1', headerName: 'Schedule', flex: 1,
            renderCell: (cell) => {

                return (
                    <>
                        <CardHeader title={<Typography style={{ fontSize: 13 }} >{cell.row.localTime}</Typography>} subheader={<Typography style={{ fontSize: 13 }} >{cell.row.utcTime}</Typography>} style={{ padding: 4 }} />
                    </>);
            },
        },
        {
            field: 'delete', headerName: '', width: 50,
            renderCell: (cell) => {
                return (<><IconButton size='small' aria-label="edit" onClick={() => handleDeleteRecur(cell.row.id)}><Delete sx={{ fontSize: 20 }} color='error' /></IconButton></>);
            },
        },
    ]
    return (
        <>
            <Card variant="outlined" style={{ height: window.innerHeight - 80 }}>
                <CardHeader style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 8 }} title={<>{'Settings'} <span style={{ fontSize: 15, fontWeight: 550, paddingLeft: 4 }}>{(IsNullOrEmptyOrUndefined(props.ReportName) === false) ? ' : ' + props.ReportName : ''}</span></>} titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}><Settings /></Avatar>} />
                <Collapse in={expand}>
                    <Divider />
                    <TabContext value={tabValue} style={{}}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="Settings" sx={{ minHeight: 44, height: 44 }}>
                                <Tab style={{ textTransform: 'none' }} label="Schedule Setting" value="1" />
                                <Tab style={{ textTransform: 'none' }} label="Email Setting" value="2" />
                                <Tab style={{ textTransform: 'none' }} label="Storage Setting" value="3" />
                            </TabList>
                        </Box>
                        <div>
                            <TabPanel value="1" style={{ padding: 6 }}>

                                <CardContent style={{ padding: 6, pointerEvents: (props.ReportType === 'Interactive') ? 'none' : '' }}>
                                    <Grid container spacing={1} style={{ paddingBottom: 5 }} >
                                        <Grid item>
                                            <FormControl sx={{ width: 120 }} size="small" required>
                                                <InputLabel style={{ fontSize: 13 }}>Frequency</InputLabel>
                                                <Select label="Frequency" style={{ fontSize: 13 }} value={frequency || ''} onChange={(e) => handleFrequency(e)}>
                                                    <MenuItem style={{ fontSize: 13 }} value={'Daily'}>{'Daily'}</MenuItem>
                                                    <MenuItem style={{ fontSize: 13 }} value={'Weekly'}>{'Weekly'}</MenuItem>
                                                    <MenuItem style={{ fontSize: 13 }} value={'Monthly'}>{'Monthly'}</MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        {
                                            (frequency === 'Monthly') &&
                                            <>
                                                <Grid item>
                                                    <FormControl sx={{ width: 120 }} size="small" required>
                                                        <InputLabel style={{ fontSize: 13 }}>Month Day</InputLabel>
                                                        <Select label="Month Day" style={{ fontSize: 13 }} value={monthday} onChange={(e) => setMonthday(e.target.value)}>
                                                            {
                                                                MONTHS.map((item) => (
                                                                    <MenuItem style={{ fontSize: 13 }} value={item}>{item}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        }
                                        {
                                            (frequency === 'Weekly') &&
                                            <>
                                                <Grid item>
                                                    <FormControl sx={{ width: 120 }} size="small" required>
                                                        <InputLabel style={{ fontSize: 13 }}>Week Day</InputLabel>
                                                        <Select label="Week Day" style={{ fontSize: 13 }} value={weekday} onChange={(e) => setWeekday(e.target.value)}>
                                                            {
                                                                WEEKDAYS.map((item) => (
                                                                    <MenuItem style={{ fontSize: 13 }} value={item.value}>{item.name}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        }
                                        {
                                            (frequency === 'Daily' || frequency === 'Weekly' || frequency === 'Monthly') &&
                                            <>
                                                <Grid item>
                                                    <FormControl sx={{ width: 100 }} size="small" required>
                                                        <InputLabel style={{ fontSize: 13 }}>Hour</InputLabel>
                                                        <Select label="Hour" style={{ fontSize: 13 }} value={hour} onChange={(e) => setHour(e.target.value)}>
                                                            {
                                                                HOURS.map((item) => (
                                                                    <MenuItem style={{ fontSize: 13 }} value={item}>{item}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item>
                                                    <FormControl sx={{ width: 100 }} size="small" required>
                                                        <InputLabel style={{ fontSize: 13 }}>Minute</InputLabel>
                                                        <Select label="Minute" style={{ fontSize: 13 }} value={minute} onChange={(e) => setMinute(e.target.value)}>
                                                            {
                                                                MINUTES.map((item) => (
                                                                    <MenuItem style={{ fontSize: 13 }} value={item}>{item}</MenuItem>
                                                                ))
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </>
                                        }
                                        <Grid item>
                                            <Button disableElevation size='small' style={{ textTransform: 'none', width: 35, height: 35 }} color="primary" variant="outlined" disabled={props.ReportType === 'Interactive' ? true : false} onClick={handleAddRecurrence}>Add</Button>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} style={{ paddingBottom: 15 }}>
                                        <Grid item xs={12}>
                                            <Typography style={{ fontSize: 12 }} color='text.secondary'>* Schedule time will automatically get converted in UTC time and executed as per UTC time.</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <Box sx={{ height: 300, width: '100%' }}>
                                                <DataGrid rowHeight={45} columnHeaderHeight={30} rows={recurrence} columns={columns} disableColumnMenu loading={loading}
                                                    initialState={{
                                                        pagination: { paginationModel: { pageSize: 5, }, },
                                                        columns: { columnVisibilityModel: { id: false }, },
                                                    }}
                                                    pageSizeOptions={[5]} disableRowSelectionOnClick
                                                    sx={{ fontSize: 13, '& .MuiDataGrid-iconSeparator': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { height: 35, minHeight: 35 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                    <Typography color="text.secondary" sx={{ fontSize: 13, color: 'red' }}>{(props.ReportType === 'Interactive') ? 'Recurrence is not available for Interactive report.' : ''}</Typography>
                                </CardContent>

                            </TabPanel>

                            <TabPanel value="2" style={{ padding: 6, height: 300 }}>
                                <CardContent style={{ padding: 6, pointerEvents: (props.ReportType === 'Interactive') ? 'none' : '' }}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <TextField required inputProps={{ maxLength: 8000, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' multiline rows={2} label='EmailTo(s)' placeholder='Support multiple Email Ids with semicolums seperated' variant="outlined" sx={{ width: '100%' }} value={emailTo || ''} onChange={(o) => setEmailTo(o.target.value)} />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} style={{ marginTop: 5, marginBottom: 5 }}>
                                        <Grid item xs={12}>
                                            <TextField inputProps={{ maxLength: 8000, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' multiline rows={2} label="EmailCc(s)" placeholder='Support multiple Email Ids with semicolums seperated' variant="outlined" sx={{ width: '100%' }} value={emailCc || ''} onChange={(o) => setEmailCc(o.target.value)} />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginTop: 5, marginBottom: 5 }}>
                                        <Grid item xs={12}>
                                            <TextField required inputProps={{ maxLength: 250, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' label="Email Subject" variant="outlined" sx={{ width: '100%' }} value={emailSubject || ''} onChange={(e) => setEmailSubject(e.target.value)} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1} style={{ marginTop: 5, marginBottom: 5 }}>
                                        <Grid item xs={12}>
                                            <TextField required inputProps={{ maxLength: 8000, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} size='small' multiline rows={4} label="Email Body" variant="outlined" sx={{ width: '100%' }} value={emailBody || ''} onChange={(e) => setEmailBody(e.target.value)} FormHelperTextProps={{ style: { fontSize: 13 } }} />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <FormGroup><FormControlLabel control={<Checkbox checked={attachementInEmail} onChange={(o) => setAttachementInEmail(o.target.checked)} />} label={<Typography style={{ fontSize: 13 }}>{props.ReportFormat === 'Html' ? 'Override Email Body with output content' : 'Add output as a attachment'}</Typography>} /></FormGroup>
                                            <Typography color="text.secondary" sx={{ fontSize: 13, color: 'red' }}>{(props.ReportType === 'Schedule' && props.ReportFormat === 'Html' && attachementInEmail) ? 'Add {0} in the email body to replace the dynamic html content at designation position.' : ''}</Typography>
                                        </Grid>
                                    </Grid>
                                    <Typography color="text.secondary" sx={{ fontSize: 13, color: 'red' }}>{(props.ReportType === 'Interactive') ? 'Recurrence is not available for Interactive report.' : ''}</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button disableElevation style={{ textTransform: 'none', width: 100 }} variant="outlined" disabled={props.ReportType === 'Interactive' ? true : false} onClick={handleEmailSave}>Save</Button>{' '}
                                    <Button disableElevation style={{ textTransform: 'none', width: 100 }} variant="outlined" disabled={props.ReportType === 'Interactive' ? true : false} onClick={handleEmailRemove}>Remove</Button>
                                </CardActions>
                            </TabPanel>

                            <TabPanel value="3" style={{ padding: 6, height: 300 }}>
                                <CardContent style={{ padding: 6, pointerEvents: (props.ReportType === 'Interactive') ? 'none' : '' }}>
                                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 10 }}>
                                        <Grid item xs={4}>
                                            <FormControl sx={{ width: '100%' }} size="small" required>
                                                <InputLabel style={{ fontSize: 13 }}>Select Storage</InputLabel>
                                                <Select label="Select Storage" style={{ fontSize: 13 }} value={storageType || ''} onChange={(o) => setStorageType(o.target.value)}>
                                                    <MenuItem value={''} style={{ fontSize: 13 }}>{'--Select Storage--'}</MenuItem>
                                                    <MenuItem value={'SFTP-Credential'} style={{ fontSize: 13 }}>{'SFTP-Credential'}</MenuItem>
                                                    <MenuItem value={'AzureStorage'} style={{ fontSize: 13 }}>{'Azure Storage'}</MenuItem>)
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    {
                                        (storageType === 'SFTP-Credential') &&
                                        <>
                                            <Grid container spacing={2} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 10 }}>
                                                <Grid item xs={4}>
                                                    <TextField size='small' inputProps={{ maxLength: 250, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} required label="Server" variant="outlined" sx={{ width: '100%' }} value={SFTPServer || ''} onChange={(o) => setSFTPServer(o.target.value)} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField size='small' inputProps={{ maxLength: 100, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} required label="Folder Name" variant="outlined" sx={{ width: '100%' }} FormHelperTextProps={{ style: { fontSize: 13 } }} value={SFTPFolder || ''} onChange={(o) => setSFTPFolder(o.target.value)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} style={{ paddingTop: 10 }}>
                                                <Grid item xs={4}>
                                                    <TextField size='small' inputProps={{ maxLength: 100, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} required label="UserName" variant="outlined" sx={{ width: '100%' }} FormHelperTextProps={{ style: { fontSize: 13 } }} value={SFTPUserId || ''} onChange={(o) => setSFTPUserId(o.target.value)} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <TextField size='small' inputProps={{ maxLength: 100, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} required type="password" label="Password" variant="outlined" sx={{ width: '100%' }} FormHelperTextProps={{ style: { fontSize: 13 } }} value={SFTPPassword || ''} onChange={(o) => setSFTPPassword(o.target.value)} />
                                                </Grid>
                                            </Grid>
                                        </>
                                    }
                                    {
                                        (storageType === 'AzureStorage') &&
                                        <>
                                            <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', paddingTop: 10 }}>
                                                <Grid item xs={8}>
                                                    <TextField size='small' inputProps={{ maxLength: 500, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} required multiline rows={3} label="Connection" variant="outlined" sx={{ width: '100%' }} value={storageConnection || ''} onChange={(e) => setStorageConnection(e.target.value)} />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1} style={{ paddingTop: 15 }}>
                                                <Grid item xs={8}>
                                                    <TextField size='small' inputProps={{ maxLength: 150, style: { fontSize: 13 } }} InputLabelProps={{ style: { fontSize: 13 } }} required label="Container Name" variant="outlined" sx={{ width: '100%' }} value={storageContainer || ''} onChange={(e) => setStorageContainer(e.target.value)} />
                                                </Grid>
                                            </Grid>
                                        </>
                                    }

                                    <Typography color="text.secondary" sx={{ fontSize: 13, color: 'red' }}>{(props.ReportType === 'Interactive') ? 'Recurrence is not available for Interactive report.' : ''}</Typography>
                                    <CardActions style={{ paddingLeft: 0 }}>
                                        <LoadingButton disableElevation disabled={props.ReportType === 'Interactive' ? true : false} style={{ textTransform: 'none', width: 100 }} variant="outlined" loading={loading} onClick={handleStorageSave}>Save</LoadingButton>
                                        {(storageType === 'SFTP-Credential') && <LoadingButton disableElevation disabled={props.ReportType === 'Interactive' ? true : false} style={{ textTransform: 'none', width: 140 }} variant="outlined" color='secondary' loading={connecting} onClick={handleTestConnection}>Test Connection</LoadingButton>}
                                    </CardActions>
                                </CardContent>

                            </TabPanel>

                        </div>
                    </TabContext>
                </Collapse>
            </Card >
        </>
    );

}
