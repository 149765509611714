import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import 'dayjs/locale/en-in';
import { IsNullOrEmptyOrUndefined } from '../Utilities/String';
import { CustomAlert } from '../Utilities/CustomAlert'
import { Avatar, Box, IconButton, TextField, Button, Card, CardContent, CardHeader, Divider, Grid, Tooltip, Autocomplete, Typography } from '@mui/material';
import { BASE_URL } from '../Utilities/APIURLs';
import { DeleteOutline, Key } from '@mui/icons-material';
import { GetJSON, PostJSON } from '../Utilities/APICall';
import { useSelector } from 'react-redux'

export function DeveloperAccess(props) {
    const userinfo = useSelector((state) => state.userinfo);

    const [allUsers, setAllUsers] = useState([]);
    const [users, setUsers] = useState([]);
    const [userName, setUserName] = React.useState('');
    const [userId, setUserId] = React.useState('');
    const [loginId, setLoginId] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [isUserEdit, setIsUserEdit] = React.useState(false);
    const [selectedId, setSelectedId] = React.useState('');
    const [disableControl, setDisableControl] = React.useState(false);
    const [role, setRole] = React.useState('Viewer');

    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (dataFetchedRef.current === false) {
            dataFetchedRef.current = true;
            getDevelopers();
            getAllUsers();
        }
    }, []);

    /** API Calls */
    const getDevelopers = () => {
        setAllUsers([])
        setLoading(true)
        var url = BASE_URL + 'Access/GetDevelopers';
        GetJSON(url, props.instance)
            .then(data => {
                setLoading(false)
                setUsers(data);
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const getAllUsers = () => {
        setAllUsers([])
        setLoading(true)
        var url = BASE_URL + 'UserMaster/GetActiveUsers';
        GetJSON(url, props.instance)
            .then(data => {
                setLoading(false)
                let names = [];
                data.forEach(arr => names.push({ 'label': arr.name, 'id': arr.id }));
                setAllUsers(names);
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const saveReportUser = () => {

        if (IsNullOrEmptyOrUndefined(userId) || userId <= 0) {
            CustomAlert('Please select user', 'error')
            return;
        }
        var url = BASE_URL + 'Access/InsertDeveloper?UserId=' + userId
        GetJSON(url, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.data.length > 0) {
                    setUsers(data.data);
                }
                else {
                    setUsers([])
                }

                handleResetUser()
                if (IsNullOrEmptyOrUndefined(data.message) === false) CustomAlert(data.message, 'error')
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });

    }
    const deleteDeveloper = (UserId) => {
        setLoading(true)
        var url = BASE_URL + 'Access/DeleteDeveloper?UserId=' + UserId
        GetJSON(url, props.instance)
            .then((data) => {
                setLoading(false)
                if (data.length > 0) {
                    setUsers(data);
                }
                else {
                    setUsers([])
                }
                CustomAlert('Developer access has been removed', 'success')
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e.message, 'error');
            });
    }
    /** API Calls End */

    const handleResetUser = () => {
        setUserName('')
        setLoginId('')
        setIsUserEdit(false);
        setSelectedId('');
        setRole('Viewer');
    }
    const handleDeleteUser = (UserId) => {
        if (window.confirm("Are you sure you want to unassign the selected user?") === true) {
            deleteDeveloper(UserId);
        }
    }
    const columnsUser = [
        { field: 'id', headerName: 'Id', },
        // { field: 'userId', headerName: 'UserId' },
        // { field: 'userName', headerName: 'Name', width: 150, sortable: false, valueGetter: (row) => `${row.row.firstName || ''} ${row.row.lastName || ''}`, },
        {
            field: 'name', headerName: '', width: 200, flex: 1,
            renderCell: (cell) => {
                return (
                    <>
                        <Typography style={{ fontSize: 13, paddingTop: 2 }}>{cell.row.firstName + ' ' + cell.row.lastName}</Typography>
                    </>
                );
            },
        },
        { field: 'loginId', headerName: 'Login Id', width: 200, sortable: true },
        {
            field: 'edit', headerName: '', width: 60,
            renderCell: (cell) => {
                return (
                    <>
                        <Tooltip title='Unassigned'><IconButton size='small' aria-label="unassigned" onClick={() => handleDeleteUser(cell.row.id)}><DeleteOutline sx={{ fontSize: 22 }} color='error' /></IconButton></Tooltip>
                    </>
                );
            },
        },
    ]
    const handleUserName = (value) => {
        if (IsNullOrEmptyOrUndefined(value) === false) {
            setUserName(value);
            setUserId(value.id);
        }
    }
    function handleNewUserClick(event) {
        navigate('/UserMaster');
    }
    return (
        <>

            <Card variant="outlined" style={{ height: window.innerHeight - 80 }}>
                <CardHeader style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 8 }} title={<>{'Developer'} <span style={{ fontSize: 12 }}>{''}</span></>} titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}><Key fontSize='small' /></Avatar>} />
                <Divider />
                <CardContent>
                    <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left', marginBottom: 10 }}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} style={{ textAlign: 'left', justifyContent: 'left' }}>
                                <Grid item xs={6}>
                                    <Autocomplete size='small' sx={{ width: '100%' }} disabled={disableControl} value={userName} onChange={(event, newValue) => { handleUserName(newValue); }} options={allUsers} renderInput={(params) => <TextField {...params} size='small' label="User Name" />} />
                                </Grid>
                                <Grid item>
                                    <Button variant="outlined" disableElevation color="primary" style={{ textTransform: 'none', width: 80, height: 37 }} onClick={saveReportUser}>{'Assign'}</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box sx={{ height: window.innerHeight - 270, width: '100%' }}>
                        <DataGrid rowHeight={30} columnHeaderHeight={30} rows={users} columns={columnsUser} disableColumnMenu loading={loading}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 10, }, },
                                columns: { columnVisibilityModel: { userId: false, id: false, }, },
                            }}
                            pageSizeOptions={[10]} disableRowSelectionOnClick
                            sx={{ fontSize: 13, '& .MuiDataGrid-iconSeparator': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { height: 35, minHeight: 35 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                        />
                    </Box>
                </CardContent>
            </Card >
        </>
    );

}
