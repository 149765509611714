import React, { useEffect, useState, useRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import 'dayjs/locale/en-in';
import { IsNullOrEmptyOrUndefined } from '../Utilities/String';
import { CustomAlert } from '../Utilities/CustomAlert'
import { Avatar, Box, Card, CardHeader, Divider, Tooltip, Collapse, Typography } from '@mui/material';
import { HistoryToggleOff, Done, Schedule, ErrorOutline } from '@mui/icons-material';
import { BASE_URL } from '../Utilities/APIURLs';
import { GetJSON } from '../Utilities/APICall';

export function ReportHistory(props) {
    const dateformat = 'DD MMM YY HH:mm:ss';

    const [expandReportHistory, setExpandReportHistory] = useState(true);
    const [transformHistoryIcon, setTransformHistoryIcon] = useState('rotate(180deg)');
    const transition = 'all 1s';

    const [reportHistory, setReportHistory] = useState([]);
    const [loading, setLoading] = React.useState(false);
    const dataFetchedRef = useRef(false);

    useEffect(() => {
        if (props.ReportInfo.ReportId > 0 && dataFetchedRef.current === false) {
            dataFetchedRef.current = true;
            getReportHistory(props.ReportInfo.ReportId);
        }
    }, [props.Refresh]);

    /** API Calls */
    const getReportHistory = (ReportId) => {
        setReportHistory([])
        setLoading(true)
        var url = ''
        if (props.ReportInfo.UserId === '') {
            url = BASE_URL + 'History/GetByReportId?ReportId=' + ReportId
        }
        else {
            url = BASE_URL + 'History/GetByUserReportId?UserId=' + props.ReportInfo.UserId + '&ReportId=' + ReportId
        }
        GetJSON(url, props.instance)
            .then(data => {
                setLoading(false)
                dataFetchedRef.current = false;
                data.forEach(item => {
                    var st = (IsNullOrEmptyOrUndefined(item.started) === false) ? 'Started:' + dayjs(item.started).utc('z').local().format(dateformat) : ''
                    var end = (IsNullOrEmptyOrUndefined(item.ended) === false) ? 'Ended:' + dayjs(item.ended).utc('z').local().format(dateformat) : ''
                    item.executed = st + ' ' + end
                });
                setReportHistory(data);
            })
            .catch((e) => {
                setLoading(false)
                CustomAlert(e, 'error');
            });

    }
    /** API Calls End */
    const historyColumns = [
        { field: 'id', headerName: 'Id', width: 90 },
        {
            field: 'status', headerName: '', width: 50, editable: false, sortable: false,
            renderCell: (cell) => {
                return (
                    <>
                        <Avatar sx={{marginTop:.4, width: 20, height: 20, bgcolor: (cell.row.status === 'success') ? '#dcefdc' : (cell.row.status === 'error' || cell.row.status === 'failed') ? '#fce8e8' : '#b8e6fa' }}>
                            {(cell.row.status === 'success') ? <Done sx={{ fontSize: 18, color: '#2e7d32' }} /> : (cell.row.status === 'error' || cell.row.status === 'failed') ? <Tooltip title={cell.row.remarks}><ErrorOutline sx={{ fontSize: 18, color: '#d32f2f' }} /></Tooltip> : <Schedule sx={{ fontSize: 18, color: '#0288d1' }} />}
                        </Avatar>
                    </>
                );
            },
        },
        {
            field: 'frequency', headerName: '', width: 70, editable: false, align: 'left',
            renderCell: (cell) => {
                return (<><Typography style={{ fontSize: 13, paddingTop: 2 }}>{cell.row.frequency}</Typography></>);
            },
        },
        {
            field: 'userName', headerName: '', width: 240, editable: false,
            renderCell: (cell) => {
                return (<><Typography style={{ fontSize: 13, paddingTop: 2 }}>{'Exectued By: ' + cell.row.userName}</Typography></>);
            },
        },
        {
            field: 'execution', headerName: '', editable: false, flex: 1,
            renderCell: (cell) => {
                return (<><Typography style={{ fontSize: 13, paddingTop: 2 }}>{cell.row.executed}</Typography></>);
            },
        },

    ];
    const handleExpandReportHistory = () => {
        if (expandReportHistory === false) {
            setExpandReportHistory(true)
            setTransformHistoryIcon('rotate(180deg)')
        }
        else {
            setExpandReportHistory(false)
            setTransformHistoryIcon('rotate(0deg)')
        }
    }
    return (
        <>
            <Card variant="outlined" style={{ height: window.innerHeight - 80 }}>
                <CardHeader style={{ paddingTop: 8, paddingBottom: 8, paddingLeft: 8 }} title={<>{'Execution History'} <span style={{ fontSize: 15, fontWeight: 550, paddingLeft: 4 }}>{(IsNullOrEmptyOrUndefined(props.ReportInfo.ReportName) === false) ? ' : ' + props.ReportInfo.ReportName  : ''}</span></>} titleTypographyProps={{ variant: 'body1' }} avatar={<Avatar variant="rounded" sx={{ width: 24, height: 24, bgcolor: 'orange' }}><HistoryToggleOff /></Avatar>} />
                <Collapse in={expandReportHistory}>
                    <Divider />
                    <Box sx={{ height: window.innerHeight - 125, width: '100%' }}>
                        <DataGrid rowHeight={27} columnHeaderHeight={0} rows={reportHistory} columns={historyColumns} disableColumnMenu loading={loading}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 20, }, },
                                columns: { columnVisibilityModel: { id: false }, },
                            }}
                            pageSizeOptions={[20]} disableRowSelectionOnClick
                            sx={{ border: 0, fontSize: 13, '& .MuiDataGrid-iconSeparator, & .MuiDataGrid-columnHeaders, & .MuiDataGrid-columnHeader': { display: 'none' }, '& .MuiTablePagination-displayedRows': { marginTop: 'revert' }, '& .MuiDataGrid-footerContainer': { height: 35, minHeight: 35, border: 0 }, "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": { outline: "none !important" } }}
                        />
                    </Box>
                </Collapse>
            </Card>
        </>
    );

}
